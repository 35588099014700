import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import jobsCache from "./jobs.cache";
import socketService from '@/services/socket/socket.service.js'

class jobsService {
  constructor() {
    this._alreadyLoaded = false

    socketService.on('jobs:create', (data) => {
      this.reloadCache()
    })

    socketService.on('jobs:update', (data) => {
      this.reloadCache()
    })

    socketService.on('jobs:archive', (data) => {
      this.reloadCache()
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/jobs/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  async cachedList() {
    if(!this._alreadyLoaded) {
      await jobsCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    if (await jobsCache.cachePresent()) {
      return await jobsCache.get()
    } else {
      await this.reloadCache()
      return await jobsCache.get()
    }
  }

  async reloadCache() {
    const jobs = await this.list(1, 1000, {})
    await jobsCache.cache({ jobs: jobs.rows })
  }

  archive(job) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!job.id)
        reject('id undefined')
      
      axios.post(apiUrl + 'registries/jobs/archive', job).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(job) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/jobs/create", job)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(job) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/jobs/update", job)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(job) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!job.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/jobs/delete", {
          id: job.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(jobId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/jobs/get", { id: jobId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new jobsService();
